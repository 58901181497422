import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../modules/components/Typography";
import AppFooter from "../modules/views/AppFooter";
import AppAppBar from "../modules/views/AppAppBar";
import withRoot from "../modules/withRoot";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import "../index.css";

function ProductNotifications() {
  let [searchParams] = useSearchParams();
  const plansRef = useRef(null);
  const scrollToPlans = () => plansRef.current.scrollIntoView();

  useEffect(() => {
    switch (true) {
      case searchParams.has("plans"):
        scrollToPlans();
        break;
      default:
        break;
    }
  }, [searchParams]);

  const shopify_badge = (
    <Container
      component="a"
      href="https://apps.shopify.com/product-notifications-1?from=sage-vye"
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box
        sx={{ mt: 5, mb: 1, width: { xs: "50%", md: "22%" } }}
        component="img"
        alt="Find Vye on the Shopify App Store"
        src="/shopify_badge_black.svg"
      />
    </Container>
  );

  return (
    <React.Fragment>
      <AppAppBar />
      <Container
        sx={{
          mt: { xs: 10, md: 15 },
          mb: { xs: 10, md: 15 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" gutterBottom marked="center" align="center">
          Vye: Automated Email Alerts
        </Typography>
        <Typography align="center">
          Increase sales with automated product email alerts to subscribers of
          your Shopify online shop.
        </Typography>
        <Container
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ mt: 5, mb: 5, width: "90%" }}
            component="img"
            alt="Feature image of the new product notifications app"
            src="/pnot.png"
          />
        </Container>
        <Typography
          variant="h4"
          gutterBottom
          marked="center"
          align="center"
          sx={{ mt: { xs: 6, md: 10 } }}
        >
          How it works
        </Typography>
        <Typography sx={{ mt: 3 }}>
          Vye sends email alerts to subscribers when you update products on your
          shop. Automated email alerts are triggered when new products are added
          or existing products are updated. There are three types of triggers.
          <br />
          <br />
          The first one is product tags. When you create products in Shopify,
          you can add tags to them. When you allow customers to subscribe to
          these tags, they will be alerted when a product with the tag they are
          interested in is added or updated. You can also have customers
          subscribe to all product updates in which case tag subscription is not
          needed. <br />
          <br />
          The second type of trigger is updates in collections. Shopify allows
          you to group products into collections. You can allow customers to
          subscribe to any collection so they can receive an alert when there is
          an update in that collection. You can also allow customers to
          subscribe to updates in all collections. <br />
          <br />
          The last type of trigger is search keywords. When customers search for
          particular products on the search page of your shop, Vye can save the
          search keywords and alert them when a product matching the keyword
          becomes available. <br />
          <br />
          There are three ways of adding subscribers so they can start receiving
          product email alerts.
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ mt: { xs: 2, md: 4 } }}>
          Add subscribers on the Dashboard
        </Typography>
        <div>
          You can manage subscribers directly on the Vye Dashboard. If you go to{" "}
          <b>View subscribers</b> &#8594; <b>Edit</b> &#8594; <b>Add</b>, you
          can enter the email address and select all the triggers for email
          alerts. You can also remove subscribers with <b>Edit</b> &#8594;{" "}
          <b>Remove</b> and entering the email address of the subscriber you
          want to remove.
        </div>
        <Typography variant="h6" gutterBottom sx={{ mt: { xs: 2, md: 4 } }}>
          Import subscribers
        </Typography>
        <div>
          If you are on the standard plan or higher, you can import subscribers
          into Vye. The file must be in CSV format. You can add triggers for
          each subscriber directly in the file. During the import you can also
          select triggers that should be applied to all the subscribers.
          {/* See{" "}
          <Link href="/product-notifications?how-to-add-search-page-search-notification">
            step-by-step instructions on how to import subscribers.
          </Link> */}
        </div>
        <Typography variant="h6" gutterBottom sx={{ mt: { xs: 2, md: 4 } }}>
          Subscribe forms
        </Typography>
        <div>
          Vye provides different forms that you can add to your online shop so
          that customers can subscribe to email alerts. The product tags form
          allows customers to either subscribe to all product updates or to
          products with specific product tags. See{" "}
          <Link href="/how-to-add-product-tags-form">
            step-by-step instructions on how to add a product tags form.
          </Link>{" "}
          The collections form allows customers to either subscribe to updates
          in all collections or specific collections. See{" "}
          <Link href="/how-to-add-collections-form">
            step-by-step instructions on how to add a collections forms.
          </Link>{" "}
          The search keywords form allow customers to save their search keywords
          and get alerted if a matching product is added. See{" "}
          <Link href="/how-to-add-search-keywords-form">
            step-by-step instructions on how to add search keywords forms.
          </Link>{" "}
          You can also add a form to your account page so that subscribers can
          select and manage triggers that you allow.
        </div>
        <div ref={plansRef}>
          <Typography
            variant="h4"
            gutterBottom
            marked="center"
            align="center"
            sx={{ mt: { xs: 6, md: 10 } }}
          >
            Plans
          </Typography>
        </div>
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table stickyHeader={true} aria-label="app plans">
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Features
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Starter
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Standard
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Advanced
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Premium
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Ultimate
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Enterprise
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Subscribe forms for product tags, collections and search
                  keywords
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Trigger emails to subscribers when products and collections
                  are updated
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Full support for setup
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Customize emails by inserting logo, changing default text and
                  styling.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Import and export subscribers
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Technical support
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  10-day free trial
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Additional custom triggers of email alerts
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Custom code for account page so that subscribers can manage
                  triggers
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Fully branded emails with custom html
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Custom integration of subscribe forms
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Custom code development
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ❌
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  ✅
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Total number of subscribers that can receive product alert
                  emails
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  10
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  100
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  500
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  1K
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  2K
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Can be selected
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Total number of emails that can be sent monthly
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  20
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  200
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  1K
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  10K
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  20K
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Can be selected
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Price
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  Free
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  $9.99/month
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  $49.99/month
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  $89.99/month
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  $159.99/month
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: { xs: "small", md: "medium" } }}
                >
                  <Link href="https://sage-apps.com/contact">Get a quote</Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {shopify_badge}
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(ProductNotifications);
