import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Markdown from './modules/components/Markdown';
import Typography from './modules/components/Typography';
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';
import withRoot from './modules/withRoot';
import terms from './modules/views/terms.md';

function Terms() {
    const [content, setContent] = React.useState({ md: "" });

    React.useEffect(() => {
        fetch(terms)
            .then((res) => res.text())
            .then((md) => {
                setContent({ md });
            })
    }, [])

    return (
        <React.Fragment>
            <AppAppBar />
            <Container>
                <Box sx={{
                    mt: { xs: 10, md: 15 },
                    mb: { xs: 10, md: 15 },
                }}>
                    <Typography variant="h3" gutterBottom marked="center" align="center">
                        Terms and Conditions
                    </Typography>
                    <Markdown>{content.md}</Markdown>
                </Box>
            </Container>
            <AppFooter />
        </React.Fragment>
    );
}

export default withRoot(Terms);
