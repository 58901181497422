import * as React from "react";
import { Route, Routes } from "react-router-dom";

import ProductNotifications from "./apps/ProductNotifications";
import ThemeFinder from "./apps/ThemeFinder";
import VyeFeedback from "./apps/VyeFeedback";
import OnceAgainCustom from "./apps/OnceAgainCustom";
import Contact from "./Contact";
import Home from "./Home";
import withRoot from "./modules/withRoot";
import Privacy from "./Privacy";
import Quote from "./Quote";
import Terms from "./Terms";
import HowToAddProductTagsForm from "./apps/HowToAddProductTagsForm";
import HowToAddCollectionsForm from "./apps/HowToAddCollectionsForm";
import HowToAddSearchKeywordsForm from "./apps/HowToAddSearchKeywordsForm";
import HowToAddAccountPageForm from "./apps/HowToAddAccountPageForm";

function Index() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="terms" element={<Terms />} />
      <Route path="contact" element={<Contact />} />
      <Route path="quote" element={<Quote />} />
      <Route path="apps" element={<ProductNotifications />} />
      <Route path="product-notifications" element={<ProductNotifications />} />
      <Route path="theme-finder" element={<ThemeFinder />} />
      <Route path="vye-feedback" element={<VyeFeedback />} />
      <Route path="once-again-fr-custom-code" element={<OnceAgainCustom />} />
      <Route
        path="how-to-add-product-tags-form"
        element={<HowToAddProductTagsForm />}
      />
      <Route
        path="how-to-add-collections-form"
        element={<HowToAddCollectionsForm />}
      />
      <Route
        path="how-to-add-search-keywords-form"
        element={<HowToAddSearchKeywordsForm />}
      />
      <Route
        path="how-to-add-account-page-form"
        element={<HowToAddAccountPageForm />}
      />
      <Route path="blog/foo" element={<ProductNotifications />} />
    </Routes>
  );
}

export default withRoot(Index);
