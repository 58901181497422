import * as React from "react";
import { Field, Form } from "react-final-form";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "./modules/components/Typography";
// import AppFooter from './modules/views/AppFooter';
import AppAppBar from "./modules/views/AppAppBar";
import { email, text, required } from "./modules/form/validation";
import RFTextField from "./modules/form/RFTextField";
import FormButton from "./modules/form/FormButton";
import withRoot from "./modules/withRoot";
import { postData } from "./Common";

function Contact() {
  const [sent, setSent] = React.useState(false);
  const [values, setValues] = React.useState({ email: "", message: "" });

  const validate = (values) => {
    const errors = required(["email", "message"], values);
    // const res = values.message & !values.message.trim()
    setValues({ email: values.email, message: values.message });

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    if (!errors.message) {
      const messageError = text(values.message);
      if (messageError) {
        errors.message = messageError;
      }
    }

    return errors;
  };

  const handleSubmit = () => {
    setSent(true);
    postData("/contact", values);
  };

  const markup = sent ? (
    <React.Fragment>
      <Typography align="center" sx={{ mt: 7 }}>
        Thank you for contacting us. We will get back to you as soon as
        possible.
      </Typography>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Form
        onSubmit={handleSubmit}
        subscription={{ submitting: true }}
        validate={validate}
      >
        {({ handleSubmit: handleSubmit2, submitting }) => (
          <Box
            component="form"
            onSubmit={handleSubmit2}
            noValidate
            sx={{ mt: 6 }}
          >
            <Field
              autoComplete="email"
              component={RFTextField}
              disabled={submitting}
              fullWidth
              label="Email"
              margin="normal"
              name="email"
              required
              size="large"
            />
            <Field
              fullWidth
              size="large"
              component={RFTextField}
              disabled={submitting}
              required
              name="message"
              autoComplete="none"
              label="Message"
              margin="normal"
              multiline
              rows={7}
            />
            <FormButton
              sx={{ mt: 3, mb: 2 }}
              disabled={submitting}
              size="large"
              color="secondary"
              fullWidth
            >
              {submitting ? "Submitting…" : "Submit"}
            </FormButton>
          </Box>
        )}
      </Form>
      <Typography align="center">
        {"By clicking submit you agree to the "}
        <Link underline="always" href="/terms">
          Terms and Conditions
        </Link>
      </Typography>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <AppAppBar />
      <Container
        sx={{
          mt: { xs: 10, md: 15 },
          mb: { xs: 10, md: 15 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" gutterBottom marked="center" align="center">
          Contact
        </Typography>
        {markup}
      </Container>
      {/* <AppFooter /> */}
    </React.Fragment>
  );
}

export default withRoot(Contact);
