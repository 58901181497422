import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";

const backgroundImage = "/online_shop.png";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#00adb5", // Average color of the background image.
        backgroundPosition: "center",
        top: { xs: 50, md: 0 },
        bottom: { xs: -25, md: 0 },
      }}
    >
      <Typography
        color="inherit"
        align="center"
        variant="h2"
        marked="center"
        sx={{
          mt: 6,
          display: { xs: "none", md: "block" },
        }}
      >
        Unleash the potential of your Shopify shop
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h3"
        marked="center"
        sx={{
          mt: 10,
          display: { xs: "block", md: "none" },
        }}
      >
        Unleash the potential of your Shopify shop
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 2, mt: 7 }}
      >
        Custom solution for challenges specific to your shop.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="/quote"
        sx={{ mt: 3, minWidth: 150 }}
      >
        Get a free quote
      </Button>
      {/* <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Discover the experience
      </Typography> */}
    </ProductHeroLayout>
  );
}
