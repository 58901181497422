import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../modules/components/Typography";
import AppFooter from "../modules/views/AppFooter";
import AppAppBar from "../modules/views/AppAppBar";
import withRoot from "../modules/withRoot";
import Button from "../modules/components/Button";
import Snackbar from "@mui/material/Snackbar";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { ocean } from "react-syntax-highlighter/dist/esm/styles/hljs";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import "../index.css";

function HowToAddSearchKeywordsForm() {
  let [searchParams] = useSearchParams();
  const [openVintageSearch, setOpenVintageSearch] = useState(false);
  const [showVintageSearchCode, setShowVintageSearchCode] = useState(false);
  const howToAddSectionRef = useRef(null);
  const scrollToHowToAddSection = () =>
    howToAddSectionRef.current.scrollIntoView();

  const vintageSearchCodeString = `<script>
  const server = 'https://pnot.sage-apps.com';
  let shop = '{{shop.permanent_domain}}';
  let form;
  
  async function sendFormData(kws, email) {   
    await fetch(server + '/subscribe' + '?shop=' + shop + '&from=vinsearchform', 
    {
      method : "POST",  
      mode : "cors",   
      cache : "no-cache",  
      credentials : "same-origin", 
      headers : {
        "Content-Type" : "application/json",
      },
      redirect : "follow",  
      referrerPolicy :"no-referrer",                                  
      body : JSON.stringify({email: email, keywords: [kws]}),  
    });
  }

  function isEmailValid(email) {
    var re = /^\\S+@\\S+\\.\\S+$/;
    return re.test(email);
  }

  function onRegister() {
    var email = document.forms["searchForm"]["email"].value;
    if(!email) return
    if(!isEmailValid(email)) {
      window.alert("Please enter a valid email address");
    } else {
      var vs_query = (new URL(document.location)).searchParams.get("q");
      if(email && vs_query) {
        document.getElementById('vs_main').innerHTML = 
          \`<p>Registration successful! You will be notified when a matching 
          product becomes available.</p>\`;
      sendFormData(vs_query, email);  
      }
    }
  }
</script>

<style>
  .vss {
      margin: 0px auto; 
      padding: 10px; 
      width: 99%; 
      min-width: 80%;
      text-align: left;
    }
  @media (max-width: 750px) {
    .vss {
      padding: 15px 10px; 
      width: 99%; 
      min-width: 80%;
    } 
  }
</style>

<div class="page-width">
  <div class="text-center">
    <form id="searchForm">
      <div id="vs_main" class="vss">
        Didn't find what you were looking for? 
        Sign up to receive an email when a matching product becomes available.
        <div style="margin-top: 30px; width: 400px;">
          <input id="vye_input" type="email" name="email" placeholder="Email" 
          value="{{customer.email}}" style="padding: 7px; width: 300px" required>
        </div>
        <div style="margin-top: 30px;">
          <button type="button" class="button button--primary"  
            style="padding: 7px 20px; background-color: #d4d4d4" 
            onclick="onRegister();">
            Sign up
          </button>
        </div>
      </div>
    </form>
   </div>
</div>
  `;

  const handleCopyVintageSearchToClipBoard = () => {
    navigator.clipboard.writeText(vintageSearchCodeString);
    setOpenVintageSearch(true);
  };

  const handleToasterCloseVintageSearch = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenVintageSearch(false);
  };

  const toggleShowVintageSearchCode = () => {
    setShowVintageSearchCode(!showVintageSearchCode);
  };

  const actionVintageSearch = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleToasterCloseVintageSearch}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const vintageSearchCodeMarkup = showVintageSearchCode ? (
    <Container sx={{ mt: { xs: 3, md: 5 } }}>
      <SyntaxHighlighter language="javascript" style={ocean}>
        {vintageSearchCodeString}
      </SyntaxHighlighter>
    </Container>
  ) : null;

  const buttonVintageSearchText = showVintageSearchCode
    ? "Hide code"
    : "Show code";

  useEffect(() => {
    switch (true) {
      case searchParams.has("add-vintage-section"):
        scrollToHowToAddSection();
        break;
      default:
        break;
    }
  }, [searchParams]);

  const shopify_badge = (
    <Container
      component="a"
      href="https://apps.shopify.com/product-notifications-1?from=how-to-add-tag-search-keywords"
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box
        sx={{ mt: 5, mb: 1, width: { xs: "50%", md: "22%" } }}
        component="img"
        alt="Find Vye on the Shopify App Store"
        src="/shopify_badge_black.svg"
      />
    </Container>
  );

  return (
    <React.Fragment>
      <AppAppBar />
      <Container
        sx={{
          mt: { xs: 10, md: 15 },
          mb: { xs: 10, md: 15 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" gutterBottom marked="center" align="center">
          How to add a Vye search keywords form
        </Typography>
        <Container
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ mt: 5, mb: 5, width: "90%" }}
            component="img"
            alt="A Vye search keywords form that can be added to a Shopify shop"
            src="/onboard3.png"
          />
        </Container>
        <Container
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography align="left" sx={{ mt: 3 }}>
            When you add a search keywords form to your search page, customers
            searching for a product on your shop can register to receive an
            email alert when a matching product becomes available. Follow the
            steps below to add the form to your search page.
          </Typography>
          <ol>
            <li>
              Go to your Shopify admin, go to <strong>Online Store</strong>, and
              then <strong>Themes</strong>.
            </li>
            <li>
              Find the theme in which you want to add the form, and then click{" "}
              <strong>Customize</strong> to open the theme editor.
            </li>
            <li>
              Navigate to the search page by opening the page selector drop-down
              menu at the top and clicking on <strong>Others</strong> and then{" "}
              <strong>Search</strong>.
            </li>
            <li>
              On the left side, click <strong>Add section</strong>. Type{" "}
              <strong>vye</strong> into the search bar and select{" "}
              <strong>Search notification</strong>. Click <strong>Save</strong>{" "}
              to save all the changes. The form may not appear in the theme
              editor but it should appear on the search page of your online
              shop.
            </li>
            <li>
              To customize the form, click on Search notification and change the
              default values. To make the form cover the whole width of the
              page, click on <strong>Apps</strong> and remove the check from{" "}
              <strong>Make section margins the same as theme</strong>. Click{" "}
              <strong>Save</strong>.
            </li>
            <li>
              To change the position of the form, click on the drag icon of the
              Apps section and move it to the desired position.
            </li>
          </ol>
        </Container>
        <Container
          component="section"
          sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}
        >
          <Typography
            variant="h4"
            gutterBottom
            marked="center"
            align="center"
            sx={{ mt: { xs: 6, md: 10 } }}
          >
            How to add a search keywords form to the search page on a vintage
            theme
          </Typography>
          <Typography align="left" sx={{ mt: 3 }}>
            If you are using a vintage theme, follow the steps below to add a
            search keywords form to the search page of your online shop.
          </Typography>
          <ol>
            <li>
              Click <strong>Copy code to clipboard</strong> below to copy the
              code.
            </li>
            <li>
              Go to your Shopify admin, go to <strong>Online Store</strong>, and
              then <strong>Themes</strong>.
            </li>
            <li>
              Find the current theme. Click <strong>Actions</strong>, and then{" "}
              <strong>Edit code</strong> to open the code editor.
            </li>
            <li>
              Enter <strong>search</strong> into the search bar at the top left.
              Find the file <strong>search.liquid</strong> and click on it to
              open.
            </li>
            <li>
              Go to the last line of the file and paste the copied code on a new
              line.
            </li>
            <li>
              Click <strong>Save</strong> and click <strong>Exit button</strong>{" "}
              on the top left corner to close the code editor.
            </li>
          </ol>
          <Typography align="left">
            Contact us if you encounter problems or if you want to customize the
            form.
          </Typography>
        </Container>
        <div>
          <Stack direction="row" spacing={8} sx={{ mt: { xs: 3, md: 5 } }}>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              component="a"
              onClick={handleCopyVintageSearchToClipBoard}
            >
              Copy code to clipboard
            </Button>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              component="a"
              onClick={toggleShowVintageSearchCode}
            >
              {buttonVintageSearchText}
            </Button>
          </Stack>

          <Snackbar
            open={openVintageSearch}
            autoHideDuration={3000}
            onClose={handleToasterCloseVintageSearch}
            message="Copied"
            action={actionVintageSearch}
          />
        </div>
        {vintageSearchCodeMarkup}
        {shopify_badge}
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(HowToAddSearchKeywordsForm);
