import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";

function StepsSmallScreen() {
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <ListItem alignItems="flex-start">
        <Stack>
          <Box component="img" src="/edit-list.svg" alt="complete" sx={image} />
          <ListItemText primary="Use the link below to briefly describe what you want to achieve with the custom app." />
        </Stack>
      </ListItem>
      <Divider />

      <ListItem alignItems="flex-start">
        <Stack>
          <Box component="img" src="/advice.svg" alt="complete" sx={image} />
          <ListItemText primary="We will get back to you within 24 hours to learn more about what you want to achieve." />
        </Stack>
      </ListItem>
      <Divider />
      <ListItem alignItems="flex-start">
        <Stack>
          <Box component="img" src="/category.svg" alt="complete" sx={image} />
          <ListItemText primary="You will receive a free quote from us that includes how long it will take to develop the app and how much it will cost. We hope you let us develop the custom app for you." />
        </Stack>
      </ListItem>
      <Divider />
    </List>
  );
}

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
  height: 55,
  my: 4,
};

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: { xs: 5, md: 10 },
          mb: { xs: 8, md: 15 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Typography
            textAlign="center"
            variant="h4"
            marked="center"
            sx={{ mb: 3 }}
          >
            Steps to your custom solution
          </Typography>
          <StepsSmallScreen />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Typography
            textAlign="center"
            variant="h4"
            marked="center"
            sx={{ mb: 8 }}
          >
            Steps to your custom solution
          </Typography>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>1.</Box>
                <Box
                  component="img"
                  src="/edit-list.svg"
                  alt="complete"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  Use the link below to briefly describe what your challenges
                  are.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>2.</Box>
                <Box component="img" src="/advice.svg" alt="info" sx={image} />
                <Typography variant="h5" align="center">
                  We will get back to you within 12 hours to learn more about
                  what you want to achieve.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>3.</Box>
                <Box
                  component="img"
                  src="/category.svg"
                  alt="quote"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  {
                    "You will receive a free quote from us that includes how long it will take to develop the solution and how much it will cost."
                  }
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          component="a"
          href="/quote"
          sx={{ mt: { xs: 5, md: 8 } }}
        >
          Get started
        </Button>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
