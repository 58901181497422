import * as React from 'react';
import { Field, Form } from 'react-final-form';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from './modules/components/Typography';
// import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import { email, text, required } from './modules/form/validation';
import RFTextField from './modules/form/RFTextField';
import FormButton from './modules/form/FormButton';
import withRoot from './modules/withRoot';
import { postData } from './Common';


function Quote() {
    const [sent, setSent] = React.useState(false);
    const [values, setValues] = React.useState({ firstName: '', lastName: '', email: '', description: '' })

    const validate = (values) => {
        const errors = required(['firstName', 'lastName', 'email', 'description'], values);

        if (!errors.email) {
            const emailError = email(values.email);
            if (emailError) {
                errors.email = emailError;
            }
        }

        if (!errors.description) {
            const error = text(values.description);
            if (error) {
                errors.description = error;
            }
        }

        if (!errors.firstName) {
            const error = text(values.firstName);
            if (error) {
                errors.firstName = error;
            }
        }

        if (!errors.lastName) {
            const error = text(values.lastName);
            if (error) {
                errors.lastName = error;
            }
        }

        setValues({ firstName: values.firstName, lastName: values.lastName, email: values.email, description: values.description })

        return errors;
    };

    const handleSubmit = () => {
        setSent(true);
        postData("/quote", values);
        console.log('QUOTE:', values);
    };

    const markup =
        sent ?
            <React.Fragment>
                <Typography align="left" sx={{ mt: 7 }}>
                    {'Your request has been successfully sent! '}
                    {'We will get in touch with you in the next 24 hours.'}
                </Typography>
            </React.Fragment> :
            <React.Fragment>
                <Typography align="left" sx={{ mt: 10 }}>
                    {'Please fill the form below and provide a brief description of what you want to achieve with the custom app. '}
                    {'We will get in touch to learn more about what you are trying to achieve. After we have gotten a good picture '}
                    {'of the app we will send you a free quote.'}
                </Typography>
                <Form onSubmit={handleSubmit} validate={validate}>
                    {({ handleSubmit: handleSubmit2 }) => (
                        <Box component="form" onSubmit={handleSubmit2} noValidate sx={{ mt: 6 }}>
                            <Field
                                autoComplete="given-name"
                                component={RFTextField}
                                fullWidth
                                label="First Name"
                                margin="normal"
                                name="firstName"
                                required
                                size="large"
                            />
                            <Field
                                autoComplete="family-name"
                                component={RFTextField}
                                fullWidth
                                label="Last Name"
                                margin="normal"
                                name="lastName"
                                required
                                size="large"
                            />
                            <Field
                                autoComplete="email"
                                component={RFTextField}
                                fullWidth
                                label="Email"
                                margin="normal"
                                name="email"
                                required
                                size="large"
                            />
                            <Field
                                fullWidth
                                size="large"
                                component={RFTextField}
                                required
                                name="description"
                                autoComplete="none"
                                label="Description"
                                // type="textarea"
                                margin="normal"
                                multiline
                                rows={7}
                            />
                            <FormButton
                                sx={{ mt: 3, mb: 2 }}
                                size="large"
                                color="secondary"
                                fullWidth
                            >
                                {'Submit'}
                            </FormButton>
                        </Box>
                    )}
                </Form>
                <Typography align="center">
                    {'By clicking submit you agree to the '}
                    <Link underline="always" href="/terms">
                        Terms and Conditions
                    </Link>
                </Typography>
            </React.Fragment>;

    return (
        <React.Fragment>
            <AppAppBar />
            <Container
                sx={{
                    mt: { xs: 10, md: 15 },
                    mb: { xs: 10, md: 15 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Typography variant="h3" gutterBottom marked="center" align="center">
                    Request for a Custom Shopify App
                </Typography>
                {markup}
            </Container>

            {/* <AppFooter /> */}
        </React.Fragment>
    );
}

export default withRoot(Quote);