import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import Typography from "../modules/components/Typography";
import AppFooter from "../modules/views/AppFooter";
import AppAppBar from "../modules/views/AppAppBar";
import { isUrl } from "../modules/form/validation";
import withRoot from "../modules/withRoot";
import { postData } from "../Common";
import SearchIcon from "@mui/icons-material/Search";
import Toolbar from "../modules/components/Toolbar";

function ThemeFinder() {
  const [resultReady, setResultReady] = React.useState(false);
  const [url, setUrl] = React.useState(false);
  const [result, setResult] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [validUrl, setValidUrl] = React.useState(true);

  const handleSubmit = async () => {
    setResultReady(false);
    if (url) {
      let v = isUrl(url);
      setValidUrl(v);
      if (!v) {
        return;
      }
      let correctedUrl = "";
      if (url.substring(0, 4) !== "http") {
        correctedUrl = "https://" + url;
      } else {
        correctedUrl = url;
      }
      setLoading(true);
      const res = await postData("/find-theme", { url: correctedUrl });
      setLoading(false);
      setResult(res);
      setResultReady(true);
    }
  };

  const handleChange = (val) => {
    if (!val.target.value) {
      setValidUrl(true);
      setResultReady(false);
    }
    setUrl(val.target.value);
  };

  const resultMarkup = () => {
    if (resultReady) {
      if (result) {
        if (result.name) {
          if (result.theme_store_id) {
            return (
              <div>
                Theme:{" "}
                <Link
                  href={`https://themes.shopify.com/themes/${result.theme_store_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {result.name}
                </Link>
              </div>
            );
          } else {
            return <div>Theme: {result.name}</div>;
          }
        } else {
          return "No Shopify theme was detected";
        }
      }
    } else if (!validUrl) {
      return "URL is invalid";
    }
  };

  const markup = (
    <React.Fragment>
      <Container
        component="section"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          noValidate
          sx={{
            mt: 5,
            mb: 5,
            width: "80%",
            alignItems: "center",
          }}
        >
          <form>
            <FormGroup row>
              <TextField
                variant="outlined"
                label="Enter shop URL to detect theme"
                name="url"
                onChange={handleChange}
                sx={{ width: { xs: "80%", md: "90%" } }}
                error={!validUrl}
              />
              <Button
                variant="contained"
                disableElevation
                onClick={handleSubmit}
                color="secondary"
                // type="submit"
                disabled={loading}
                sx={{ width: { xs: "20%", md: "10%" } }}
              >
                <SearchIcon fontSize="small" />
              </Button>
            </FormGroup>
          </form>
        </Box>
        <Box>{resultMarkup()}</Box>
      </Container>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <AppAppBar />
      <Container
        sx={{
          mt: { xs: 18, md: 25 },
          mb: { xs: 18, md: 25 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" gutterBottom marked="center" align="center">
          Shopify Theme Finder
        </Typography>
        {markup}
      </Container>
      <Box
        sx={{
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          backgroundColor: "#F6F6F6",
          textAlign: "center",
        }}
      >
        <Toolbar style={{ justifyContent: "center" }}>
          <Typography sx={{ fontSize: { xs: 10, md: 13 } }}>
            <AppFooter />
          </Typography>
        </Toolbar>
      </Box>
    </React.Fragment>
  );
}

export default withRoot(ThemeFinder);
