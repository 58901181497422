import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "../modules/components/Typography";
import AppFooter from "../modules/views/AppFooter";
import AppAppBar from "../modules/views/AppAppBar";
import withRoot from "../modules/withRoot";
import Button from "../modules/components/Button";
import Snackbar from "@mui/material/Snackbar";
import SyntaxHighlighter from "react-syntax-highlighter";
import { ocean } from "react-syntax-highlighter/dist/esm/styles/hljs";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import "../index.css";

function HowToAddAccountPageForm() {
  const [openVintage, setOpenVintage] = useState(false);
  const [showCustomCode, setShowVintageCode] = useState(false);
  const handleCopyVintageToClipBoard = () => {
    navigator.clipboard.writeText(customCodeString);
    setOpenVintage(true);
  };

  const handleToasterCloseCustom = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenVintage(false);
  };

  const toggleShowVintageCode = () => {
    setShowVintageCode(!showCustomCode);
  };

  const actionCustom = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleToasterCloseCustom}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const customCodeString = `{% comment %} 
----------------------------------------------------------------------------------------
Vye code for adding email alert triggers to account page
Instructions on how to add this code: https://sage-apps.com/how-to-add-account-page-form
Version: 2.1
----------------------------------------------------------------------------------------
{% endcomment %}

{% assign vye_accnt_cstmz_title = 'Email alerts' %}
{% assign vye_accnt_cstmz_description = 
  'Do you want to receive email alerts when new products 
   are added? Select your preference.' %}
{% assign vye_accnt_select_placeholder = 'select' %}
{% assign vye_accnt_keyword_placeholder = 'press enter to add' %}

<style>
  #vye_accnt_loader {
    border: 4px solid #f2f2f2;
    border-radius: 50%;
    border-top: 4px solid #71767a;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 0.7s linear infinite;
    animation: spin 0.7s linear infinite;
    display: block;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .multiselect-dropdown {
    display: inline-block;
    padding: 2px 5px 0px 5px;
    border-radius: 4px;
    border: solid 1px #ced4da;
    background-color: white;
    position: relative;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
  }
  .multiselect-dropdown span.optext,
  .multiselect-dropdown span.placeholder {
    margin-right: 0.5em;
    margin-bottom: 2px;
    padding: 3px 7px;
    border-radius: 4px;
    display: inline-block;
  }
  .multiselect-dropdown span.optext {
    background-color: lightgray;
    padding: 1px 0.75em;
  }
  .multiselect-dropdown span.optext .optdel {
    float: right;
    margin: 0 -6px 1px 5px;
    font-size: 0.7em;
    margin-top: 2px;
    cursor: pointer;
    color: #666;
  }
  .multiselect-dropdown span.optext .optdel:hover {
    color: tomato;
  }
  .multiselect-dropdown span.placeholder {
    color: #ced4da;
  }
  .multiselect-dropdown-list-wrapper {
    box-shadow: gray 0 3px 8px;
    z-index: 100;
    padding: 2px;
    border-radius: 4px;
    border: solid 1px #ced4da;
    display: none;
    margin: -1px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: white;
  }
  .multiselect-dropdown-list-wrapper .multiselect-dropdown-search {
    margin-bottom: 5px;
  }
  .multiselect-dropdown-search {
    padding: 7px 0px 7px 7px;
    border: 0px;
    font-size: 1em;
    width: 99%;
  }

  .multiselect-dropdown-list {
    padding: 2px;
    height: 15rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .multiselect-dropdown-list::-webkit-scrollbar {
    width: 6px;
  }
  .multiselect-dropdown-list::-webkit-scrollbar-thumb {
    background-color: #bec4ca;
    border-radius: 3px;
  }
  .multiselect-dropdown-list div {
    padding: 5px;
  }
  .multiselect-dropdown-list input {
    height: 1.15em;
    width: 1.15em;
    margin-right: 0.35em;
  }
  .multiselect-dropdown-list div:hover {
    background-color: #ced4da;
  }
  .multiselect-dropdown-all-selector {
    border-bottom: solid 1px #999;
  }
  .vye_accnt_cls_kws {
    padding: 15px 0;
  }
  .vye_accnt_cls_kws span.optext {
    margin-right: 0.5em;
    margin-bottom: 2px;
    padding: 3px 7px;
    border-radius: 4px;
    display: inline-block;
  }
  .vye_accnt_cls_kws span.optext {
    background-color: lightgray;
    padding: 1px 0.75em;
    margin: 5px 10px 5px 0px;
  }
  .vye_accnt_cls_kws span.optext .optdel {
    float: right;
    margin: 0 -6px 1px 5px;
    font-size: 0.7em;
    margin-top: 2px;
    cursor: pointer;
    color: #666;
  }
  .vye_accnt_cls_kws span.optext .optdel:hover {
    color: tomato;
  }
  .vye_accnt_cls_input {
    border-radius: 4px;
    border: solid 1px #ced4da;
    padding: 12px;;
    background-color: white;
  }
  .vye_accnt_cls_input::placeholder {
    font-size: 1.1em;
    color: #ced4da;
  }
  .vye_accnt_hide {
    display: none;
  }
  #vye_accnt_button {
      margin: 0;
      padding: 0;
  }
  .vye_accnt_labl {
    padding-bottom: 0.25em;
    font-size: 1em;
  }
  .vye_accnt_top {
    margin-bottom: 2em;
    font-size: 1em;
  }
  .vye_accnt_title {
    font-size: 1.3em;
  }
</style>

<div class="vye_accnt_top">
  <h2>{{ vye_accnt_cstmz_title }}</h2>
  <div id="vye_accnt_loader">
    <div></div>
  </div>
  <div id="vye_accnt_no_triggers" class="vye_accnt_hide">
    <p>Something went wrong.</p>
  </div>
  <div id="vye_accnt_trigger_ctnt" class="vye_accnt_hide">
    <p>{{ vye_accnt_cstmz_description }}
    </p>
    <div id="vye_accnt_selection"></div>
  </div> 
</div>

<script>
  var vye_accnt_page_endpoint = "https://pnot.sage-apps.com";
  var vye_accnt_page_shop = "{{shop.permanent_domain}}";
  var vye_accnt_selected;
  var vye_accnt_triggers = [];

  function MultiselectDropdown(options) {
    var config = {
      search: true,
      height: "15rem",
      placeholder: "{{ vye_accnt_select_placeholder}}",
      txtSelected: "selected",
      txtAll: "All",
      txtRemove: "Remove",
      txtSearch: "search",
      ...options,
    };
    function newEl(tag, attrs) {
      var e = document.createElement(tag);
      if (attrs !== undefined)
        Object.keys(attrs).forEach((k) => {
          if (k === "class") {
            Array.isArray(attrs[k])
              ? attrs[k].forEach((o) => (o !== "" ? e.classList.add(o) : 0))
              : attrs[k] !== ""
              ? e.classList.add(attrs[k])
              : 0;
          } else if (k === "style") {
            Object.keys(attrs[k]).forEach((ks) => {
              e.style[ks] = attrs[k][ks];
            });
          } else if (k === "text") {
            attrs[k] === ""
              ? (e.innerHTML = "&nbsp;")
              : (e.innerText = attrs[k]);
          } else e[k] = attrs[k];
        });
      return e;
    }

    document.querySelectorAll("select[multiple]").forEach((el, k) => {
      var div = newEl("div", {
        class: "multiselect-dropdown",
        style: {
          width: config.style?.width ?? el.clientWidth + "px",
          padding: config.style?.padding ?? "",
        },
      });
      el.style.display = "none";
      el.parentNode.insertBefore(div, el.nextSibling);
      var listWrap = newEl("div", {
        class: "multiselect-dropdown-list-wrapper",
      });
      var list = newEl("div", {
        class: "multiselect-dropdown-list",
        style: { height: config.height },
      });
      var search = newEl("input", {
        class: ["multiselect-dropdown-search"],
        style: {
          display:
            el.attributes["multiselect-search"]?.value === "true"
              ? "block"
              : "none",
        },
        placeholder: config.txtSearch,
      });
      listWrap.appendChild(search);
      div.appendChild(listWrap);
      listWrap.appendChild(list);

      el.loadOptions = () => {
        list.innerHTML = "";

        if (el.attributes["multiselect-select-all"]?.value == "true") {
          var op = newEl("div", {
            class: "multiselect-dropdown-all-selector",
          });
          var ic = newEl("input", { type: "checkbox" });
          op.appendChild(ic);
          op.appendChild(newEl("label", { text: config.txtAll }));

          op.addEventListener("click", () => {
            op.classList.toggle("checked");
            op.querySelector("input").checked =
              !op.querySelector("input").checked;

            var ch = op.querySelector("input").checked;
            list
              .querySelectorAll(
                ":scope > div:not(.multiselect-dropdown-all-selector)"
              )
              .forEach((i) => {
                if (i.style.display !== "none") {
                  i.querySelector("input").checked = ch;
                  i.optEl.selected = ch;
                }
              });

            el.dispatchEvent(new Event("change"));
          });
          ic.addEventListener("click", (ev) => {
            ic.checked = !ic.checked;
          });
          el.addEventListener("change", (ev) => {
            let itms = Array.from(
              list.querySelectorAll(
                ":scope > div:not(.multiselect-dropdown-all-selector)"
              )
            ).filter((e) => e.style.display !== "none");
            let existsNotSelected = itms.find(
              (i) => !i.querySelector("input").checked
            );
            if (ic.checked && existsNotSelected) ic.checked = false;
            else if (ic.checked == false && existsNotSelected === undefined)
              ic.checked = true;
          });

          list.appendChild(op);
        }

        Array.from(el.options).map((o) => {
          var op = newEl("div", {
            class: o.selected ? "checked" : "",
            optEl: o,
          });
          var ic = newEl("input", {
            type: "checkbox",
            checked: o.selected,
          });
          op.appendChild(ic);
          op.appendChild(newEl("label", { text: o.text }));

          op.addEventListener("click", () => {
            op.classList.toggle("checked");
            op.querySelector("input").checked =
              !op.querySelector("input").checked;
            op.optEl.selected = !!!op.optEl.selected;
            el.dispatchEvent(new Event("change"));
          });
          ic.addEventListener("click", (ev) => {
            ic.checked = !ic.checked;
          });
          o.listitemEl = op;
          list.appendChild(op);
        });
        div.listEl = listWrap;

        div.refresh = () => {
          div
            .querySelectorAll("span.optext, span.placeholder")
            .forEach((t) => div.removeChild(t));
          var sels = Array.from(el.selectedOptions);
          if (
            sels.length >
            (el.attributes["multiselect-max-items"]?.value ?? 5)
          ) {
            div.appendChild(
              newEl("span", {
                class: ["optext", "maxselected"],
                text: sels.length + " " + config.txtSelected,
              })
            );
          } else {
            sels.map((x) => {
              var c = newEl("span", {
                class: "optext",
                text: x.text,
                srcOption: x,
              });
              if (el.attributes["multiselect-hide-x"]?.value !== "true")
                c.appendChild(
                  newEl("span", {
                    class: "optdel",
                    text: "🗙",
                    title: config.txtRemove,
                    onclick: (ev) => {
                      c.srcOption.listitemEl.dispatchEvent(
                        new Event("click")
                      );
                      div.refresh();
                      ev.stopPropagation();
                    },
                  })
                );

              div.appendChild(c);
            });
          }
          if (0 == el.selectedOptions.length)
            div.appendChild(
              newEl("span", {
                class: "placeholder",
                text:
                  el.attributes["placeholder"]?.value ?? config.placeholder,
              })
            );
        };
        div.refresh();
      };
      el.loadOptions();

      search.addEventListener("input", () => {
        list
          .querySelectorAll(
            ":scope div:not(.multiselect-dropdown-all-selector)"
          )
          .forEach((d) => {
            var txt = d.querySelector("label").innerText.toUpperCase();
            d.style.display = txt.includes(search.value.toUpperCase())
              ? "block"
              : "none";
          });
      });

      div.addEventListener("click", () => {
        div.listEl.style.display = "block";
        search.focus();
        search.select();
      });

      document.addEventListener("click", function (event) {
        if (!div.contains(event.target)) {
          listWrap.style.display = "none";
          div.refresh();
        }
      });
    });

    if (vye_accnt_triggers.includes("keywords")) {
      var kws_input = document.getElementById("vye_accnt_kws_input");
      var kws_div = document.getElementById("vye_accnt_kws");
      kws_div.style.display = "block";
      kws_div.style.width = config.style.width;
      kws_input.style.width = config.style.width;
      kws_input.addEventListener("keypress", function (event) {
        if (event.key === "Enter") {
          event.preventDefault();
          const kw = kws_input.value.trim();
          if (kw.length > 0) {
            vye_accnt_selected.keywords.push(kw);
            vye_accnt_selected.keywords = [
              ...new Set(vye_accnt_selected.keywords),
            ];
            kws_div.refresh();
            kws_input.value = "";
            vye_accnt_page_save();
          }
        }
      });

      kws_div.refresh = () => {
        kws_div.replaceChildren();
        vye_accnt_selected.keywords.map((x) => {
          var c = newEl("span", {
            class: "optext",
            text: x,
            srcOption: vye_accnt_selected.keywords.indexOf(x),
          });
          c.appendChild(
            newEl("span", {
              class: "optdel",
              text: "🗙",
              title: config.txtRemove,
              onclick: (ev) => {
                vye_accnt_selected.keywords.splice(c.srcOption, 1);
                vye_accnt_page_save();
                kws_div.refresh();
              },
            })
          );

          kws_div.appendChild(c);
        });
      };
      kws_div.refresh();
    }
  }

  function vye_accnt_page_fetch() {
    fetch(
      vye_accnt_page_endpoint +
        "/account-page" +
        "?shop=" +
        vye_accnt_page_shop +
        "&email=" +
        "{{customer.email}}"
    )
      .then((res) => res.json())
      .then(
        (res) => {
          let at_least_one_valid_trigger = false;
          if (res.triggers && res.triggers.length) {
            if (!res.triggers.includes("keywords")) {
              for (const t of res.triggers) {
                if (res.options && res.options[t] && res.options[t].length) {
                  at_least_one_valid_trigger = true;
                  break;
                }
              }
            } else at_least_one_valid_trigger = true;
          }

          document.getElementById("vye_accnt_loader").style.display = "none";

          if (at_least_one_valid_trigger) {
            document.getElementById("vye_accnt_trigger_ctnt").style.display =
              "block";
            let accnt_sels = document.getElementById("vye_accnt_selection");
      
            for (const t of res.triggers) {
              if (!res.selected[t]) res.selected[t] = [];
              if (t !== "keywords") {
                let opts = "";
                if (res && res.options && res.options[t]) {
                  for (const v of res.options[t]) {
                    if (
                      res.selected &&
                      res.selected[t] &&
                      res.selected[t].includes(v)
                    )
                      opts += \`<option selected>$\{v}</option>\`;
                    else opts += \`<option>$\{v}</option>\`;
                  }
                  let t_mkup = \`
                  <div class="vye_accnt_labl"><label>$\{
                    res.trigger_labels[t]
                  }:</label></div>
                  <select
                    name="$\{t}"
                    id="$\{t}"
                    multiple
                    multiselect-search="true"
                    multiselect-max-items="3"
                    onchange="vye_accnt_selected['$\{t}'] = (Array.from(this.selectedOptions).map(x=>x.value??x.text)); vye_accnt_page_save();"
                  >
                    $\{opts}
                  </select><br /><br />\`;
                  accnt_sels.insertAdjacentHTML("beforeEnd", t_mkup);
                }
              }
            }
      
            vye_accnt_selected = JSON.parse(JSON.stringify(res.selected));
            vye_accnt_triggers = res.triggers;
      
            if (res.triggers.includes("keywords")) {
              let k_mkup = \`
              <div class="vye_accnt_labl"><label>$\{
                res.trigger_labels["keywords"]
              }:</label></div>
              <input
                class="vye_accnt_cls_input"
                type="text"
                id="vye_accnt_kws_input"
                name="name"
                placeholder="{{ vye_accnt_keyword_placeholder }}"
              />
            <div class="vye_accnt_cls_kws vye_accnt_hide" id="vye_accnt_kws"></div>\`;
              accnt_sels.insertAdjacentHTML("beforeEnd", k_mkup);
            }
          } else {
            document.getElementById("vye_accnt_no_triggers").innerHTML = \`<p>$\{
              res.error_msg
            }</p>\`;
            document.getElementById("vye_accnt_no_triggers").style.display =
              "block";
          }
          if(doc_is_loaded){
            MultiselectDropdown({ style: { width: "55vh" } });
          } else {
            window.addEventListener("load", () => {
              MultiselectDropdown({ style: { width: "55vh" } });
            });
          }
        },
        (error) => {
          document.getElementById("vye_accnt_loader").style.display = "none";
        }
      );
  }
  
  vye_accnt_page_fetch();
  
  function doc_is_loaded() {
    return document.readyState === "complete";
  }

  function vye_accnt_page_save() {
    let new_data = { email: "{{customer.email}}" };
    for (const t of vye_accnt_triggers)
      if (vye_accnt_selected[t] && vye_accnt_selected[t].length) 
        new_data[t] = vye_accnt_selected[t];
    fetch(
      vye_accnt_page_endpoint +
        "/subscribe" +
        "?shop=" +
        vye_accnt_page_shop +
        \`&remove=$\{vye_accnt_triggers.join()}&from=account\`,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(new_data),
      }
    );
  }
</script>`;

  const customCodeMarkup = showCustomCode ? (
    <Container sx={{ mt: { xs: 3, md: 5 } }}>
      <SyntaxHighlighter language="html" style={ocean}>
        {customCodeString}
      </SyntaxHighlighter>
    </Container>
  ) : null;

  const buttonText = showCustomCode ? "Hide code" : "Show code";

  return (
    <React.Fragment>
      <AppAppBar />
      <Container
        sx={{
          mt: { xs: 10, md: 15 },
          mb: { xs: 10, md: 15 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" gutterBottom marked="center" align="center">
          How to add Vye account page form
        </Typography>

        <Container
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            mt: { xs: 3, md: 5 },
          }}
        >
          <Typography align="left" sx={{ mt: 3 }}>
            If you are on the Vye advanced plan or higher, you can add an
            account page form to your Shopify shop, so that customers can change
            their email alert triggers when they log in. They can select which
            product tags or collections should trigger an email alert when
            updated. They can also directly manage search keywords that should
            trigger emails. Contact us if you would like to integrate additional
            custom email triggers. Follow the steps below to add the account
            page form.
          </Typography>
          <ol>
            <li>
              Go to <strong>Vye Settings</strong>, go to{" "}
              <strong>Account Page Selection Options</strong> section, and
              select which alert triggers should be shown on the account page.
              If you select <strong>Product Tags</strong>, select which tags
              customers can subscribe to in the{" "}
              <strong>Product Tags Subscription</strong> section. If you select{" "}
              <strong>Collections</strong>, select which collections customers
              can subscribe to in the <strong>Collections Subscription</strong>{" "}
              section. Save all the changes.
            </li>
            <li>
              Go to your Shopify admin, go to <strong>Online Store</strong>, and
              then <strong>Themes</strong>.
            </li>
            <li>
              Find the theme you are using and click on{" "}
              <strong>Edit code</strong> to open the code editor.
            </li>
            <li>
              On the left side, click on <strong>Sections</strong>, and then{" "}
              <strong>Add a new section.</strong>
            </li>
            <li>
              Enter <strong>vye-account-page-form</strong> as filename in the
              popup box, select <strong>liquid</strong> as filetype and click{" "}
              <strong>Done</strong>. The file will be opened.
            </li>
            <li>
              {" "}
              Delete all the template code in the file, if there is any. Click{" "}
              <strong>Copy code to clipboard</strong> below to copy the code.
            </li>
            <li>
              Paste the copied code into the file and click{" "}
              <strong>Save</strong>.
            </li>
            <li>
              Find and open the account page file{" "}
              <strong>customers/account.liquid</strong> and add
              <strong> &#123;% section 'vye-account-page-form' %&#125; </strong>
              to the bottom of the file just before the last{" "}
              <strong>&lt;/div&gt;</strong> or whereever you want to have it and
              save it.
            </li>
            <li>
              Exit the code editor and check to see if the form appears on the
              account page of your store.
            </li>
            <li>
              To change the text on the form, go to the top of the file and
              change the text.
            </li>
          </ol>
        </Container>
        <div>
          <Stack direction="row" spacing={8} sx={{ mt: { xs: 3, md: 5 } }}>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              component="a"
              onClick={handleCopyVintageToClipBoard}
            >
              Copy code to clipboard
            </Button>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              component="a"
              onClick={toggleShowVintageCode}
            >
              {buttonText}
            </Button>
          </Stack>

          <Snackbar
            open={openVintage}
            autoHideDuration={3000}
            onClose={handleToasterCloseCustom}
            message="Copied"
            action={actionCustom}
          />
        </div>
        {customCodeMarkup}
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(HowToAddAccountPageForm);
