import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import * as React from "react";
import { Field, Form } from "react-final-form";
import Slider from "@mui/material/Slider";

import { postData } from "../Common";
import Toolbar from "../modules/components/Toolbar";
import Typography from "../modules/components/Typography";
import FormButton from "../modules/form/FormButton";
import RFTextField from "../modules/form/RFTextField";
import AppAppBar from "../modules/views/AppAppBar";
import AppFooter from "../modules/views/AppFooter";
import withRoot from "../modules/withRoot";

function VyeFeedback() {
  const [score, setScore] = React.useState();
  const [sent, setSent] = React.useState(false);
  const [response, setResponse] = React.useState({
    source: undefined,
    score: undefined,
    reason: undefined,
    improve: undefined,
  });
  const [error, setError] = React.useState({
    sourceError: false,
    reasonError: false,
    scoreError: false,
  });

  const handleSubmit = () => {
    let sourceError =
      response.source === undefined ||
      (response.source === "other" && !response.specify);
    let reasonError = !response.reason;
    let scoreError = !response.score;
    if (!sourceError && !reasonError && !scoreError) {
      setSent(true);
      const urlParams = new URLSearchParams(window.location.search);
      response.shop = urlParams.get("sm");
      postData("/vye-feedback", response);
    }
    setError({
      sourceError: sourceError,
      reasonError: reasonError,
      scoreError: scoreError,
    });
  };

  const validate = (newResponse) => {
    if (
      newResponse.source !== response.source ||
      score !== response.score ||
      newResponse.reason !== response.reason
    ) {
      setError({
        sourceError: false,
        reasonError: false,
        scoreError: false,
      });
    }
    newResponse.score = score;
    setResponse(newResponse);
  };

  const scores = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ];

  const handleSliderChange = (_, newScore) => {
    setScore(newScore);
  };

  const sourceError = () => {
    return error.sourceError ? (
      <span style={{ color: "tomato" }}>Required</span>
    ) : null;
  };

  const scoreError = () => {
    return error.scoreError ? (
      <span style={{ color: "tomato" }}>Required</span>
    ) : null;
  };

  const reasonError = () => {
    return error.reasonError ? (
      <span style={{ color: "tomato" }}>Required</span>
    ) : null;
  };

  const submitError = () => {
    return error.sourceError || error.scoreError || error.reasonError ? (
      <span style={{ color: "tomato" }}>
        Please fill all the required fields
      </span>
    ) : null;
  };

  const specify = () => {
    return response.source === "other" ? (
      <Field
        fullWidth
        size="small"
        component={RFTextField}
        required
        name="specify"
        autoComplete="none"
        label="Please specify"
        multiline
        margin="normal"
        rows={2}
      />
    ) : null;
  };

  const markup = sent ? (
    <React.Fragment>
      <Typography align="center" sx={{ mt: 7 }}>
        Thank you for the feedback.
      </Typography>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Container
        component="section"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          noValidate
          sx={{
            mt: 0,
            mb: 0,
            width: "80%",
            alignItems: "center",
          }}
        >
          <Form
            onSubmit={handleSubmit}
            subscription={{ submitting: true }}
            validate={validate}
          >
            {({ handleSubmit: handleSubmit2, submitting }) => (
              <Box
                component="form"
                onSubmit={handleSubmit2}
                noValidate
                sx={{ mt: 6 }}
              >
                <Typography>How did you find Vye?</Typography>
                <Box
                  sx={{
                    mt: { xs: 1, md: 3 },
                  }}
                >
                  <Box sx={{ mt: { xs: 0.3, md: 0.5 } }}>
                    <label>
                      <Field
                        name="source"
                        component="input"
                        type="radio"
                        value="shopify_search"
                      />{" "}
                      Shopify App Store Search
                    </label>
                  </Box>
                  <Box sx={{ mt: { xs: 0.3, md: 0.5 } }}>
                    <label>
                      <Field
                        name="source"
                        component="input"
                        type="radio"
                        value="shopify_recommendation"
                      />{" "}
                      Shopify App Store Recommendation
                    </label>
                  </Box>
                  <Box sx={{ mt: { xs: 0.3, md: 0.5 } }}>
                    <label>
                      <Field
                        name="source"
                        component="input"
                        type="radio"
                        value="shopify_community"
                      />{" "}
                      Shopify Community
                    </label>
                  </Box>
                  <Box sx={{ mt: { xs: 0.3, md: 0.5 } }}>
                    <label>
                      <Field
                        name="source"
                        component="input"
                        type="radio"
                        value="facebook"
                      />{" "}
                      Facebook
                    </label>
                  </Box>
                  <Box sx={{ mt: { xs: 0.3, md: 0.5 } }}>
                    <label>
                      <Field
                        name="source"
                        component="input"
                        type="radio"
                        value="twitter"
                      />{" "}
                      Twitter
                    </label>
                  </Box>
                  <Box sx={{ mt: { xs: 0.3, md: 0.5 } }}>
                    <label>
                      <Field
                        name="source"
                        component="input"
                        type="radio"
                        value="reddit"
                      />{" "}
                      Reddit
                    </label>
                  </Box>
                  <Box sx={{ mt: { xs: 0.3, md: 0.5 } }}>
                    <label>
                      <Field
                        name="source"
                        component="input"
                        type="radio"
                        value="pinterest"
                      />{" "}
                      Pinterest
                    </label>
                  </Box>
                  <Box sx={{ mt: { xs: 0.3, md: 0.5 } }}>
                    <label>
                      <Field
                        name="source"
                        component="input"
                        type="radio"
                        value="other"
                      />{" "}
                      Other
                    </label>
                  </Box>
                  {specify()}
                </Box>
                {sourceError()}
                <Typography
                  sx={{
                    mt: { xs: 3, md: 5 },
                  }}
                >
                  On a scale from 0 to 10, how likely are you to recommend Vye
                  to a friend or colleague?
                </Typography>
                <Slider
                  sx={{
                    mt: { xs: 1, md: 3 },
                  }}
                  onChange={handleSliderChange}
                  step={1}
                  valueLabelDisplay="auto"
                  marks={scores}
                  max={10}
                  color="primary"
                />
                {scoreError()}
                <Typography
                  sx={{
                    mt: { xs: 3, md: 5 },
                  }}
                >
                  What’s the main reason for your score above?
                </Typography>
                <Field
                  fullWidth
                  size="large"
                  component={RFTextField}
                  disabled={submitting}
                  required
                  name="reason"
                  autoComplete="none"
                  margin="normal"
                  multiline
                  rows={5}
                />
                {reasonError()}
                <Typography
                  sx={{
                    mt: { xs: 3, md: 5 },
                  }}
                >
                  What can we do to improve Vye?
                </Typography>
                <Field
                  fullWidth
                  size="large"
                  component={RFTextField}
                  disabled={submitting}
                  required
                  name="improve"
                  autoComplete="none"
                  // label="Message"
                  margin="normal"
                  multiline
                  rows={5}
                />
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={submitting}
                  size="large"
                  color="secondary"
                  fullWidth
                >
                  {submitting ? "Submitting…" : "Submit"}
                </FormButton>
                {submitError()}
              </Box>
            )}
          </Form>
        </Box>
      </Container>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <AppAppBar />
      <Container
        sx={{
          mt: { xs: 8, md: 15 },
          mb: { xs: 8, md: 15 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" gutterBottom marked="center" align="center">
          Vye: Automated Email Alerts Feedback Form
        </Typography>
        {markup}
      </Container>
      <Box
        sx={{
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          backgroundColor: "#F6F6F6",
          textAlign: "center",
        }}
      >
        <Toolbar style={{ justifyContent: "center" }}>
          <Typography sx={{ fontSize: { xs: 10, md: 13 } }}>
            <AppFooter />
          </Typography>
        </Toolbar>
      </Box>
    </React.Fragment>
  );
}

export default withRoot(VyeFeedback);
