import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../modules/components/Typography";
import AppFooter from "../modules/views/AppFooter";
import AppAppBar from "../modules/views/AppAppBar";
import withRoot from "../modules/withRoot";
import Link from "@mui/material/Link";
import Button from "../modules/components/Button";
import Snackbar from "@mui/material/Snackbar";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { ocean } from "react-syntax-highlighter/dist/esm/styles/hljs";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import "../index.css";

function HowToAddProductTagsForm() {
  let [searchParams] = useSearchParams();
  const [openVintage, setOpenVintage] = useState(false);
  const [showVintageCode, setShowVintageCode] = useState(false);
  const howToAddSectionRef = useRef(null);
  const scrollToHowToAddSection = () =>
    howToAddSectionRef.current.scrollIntoView();

  const handleCopyVintageToClipBoard = () => {
    navigator.clipboard.writeText(vintageCodeString);
    setOpenVintage(true);
  };

  const handleToasterCloseVintage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenVintage(false);
  };

  const toggleShowVintageCode = () => {
    setShowVintageCode(!showVintageCode);
  };

  const actionVintage = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleToasterCloseVintage}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const vintageCodeString = `<script>
    const endpoint = 'https://pnot.sage-apps.com';
    let shop = '{{shop.permanent_domain}}';
    let tags;
    fetch(
        endpoint + '/shop-product-tags' +
        '?shop=' + shop + '&id=' + '{{customer.id}}')
        .then((res) => res.json())
        .then(
            (result) => {
              if(!result.disable && !result.subscribed) {
                tags = result.tags;
                generateForm();
              }
            },
            (error) => {
              console.log('ERROR: ', error);
            });
  
        const emailInput = \`
            <div style="margin-top: 30px; width: 300px">
              <input id="vye_input" type="email"  value="{% if customer %}{{ customer.email }}{% endif %}" 
                placeholder="Your email" name="email" autocorrect="off" autocapitalize="off" 
                style="padding: 7px; width: 300px" required>
            </div>
            <div style="margin-top: 30px;">
              <button id="Subscribe" type="submit" name="commit" style="padding: 7px 20px; 
                background-color: {{ section.settings.button_color }}">
                Subscribe
              </button>
            </div>
            \`;
  
    const tagMargin = "margin: 10px"
    const labelMargin = "margin: 8px"
  
    const generateTagCheckbox = (tag) => {
      return(\`<div style="$\{tagMargin}">
                  <input type="checkbox" id="$\{tag}" name="$\{tag}" onclick="tagClicked()">
                  <label style="$\{labelMargin}" for="$\{tag}">$\{tag}</label>
              </div>\`)
    }
  
    function generateTagCheckboxes() {
      let res = '';
      for(let tag of tags) {
        res += generateTagCheckbox(tag);
      }
      return res;
    }
  
    function allClicked(checked) {  
      tags.forEach(tag => document.getElementById(tag).disabled = checked);
      displayCheckboxErrorMessage(false);
    }
  
    function tagClicked() {
      let atLeastOneChecked = false;
      tags.forEach(tag => atLeastOneChecked ||= document.getElementById(tag).checked);
      document.getElementById('all').disabled = atLeastOneChecked;
      displayCheckboxErrorMessage(false);
    }
  
    function isFormComplete() {
      if(!tags.length) {
        return true;
      }
      return isAtLeastOneCheckboxChecked();
    }
  
    function isAtLeastOneCheckboxChecked() {
      let atLeastOneChecked = false;
      tags.forEach(tag => atLeastOneChecked ||= document.getElementById(tag).checked);
      atLeastOneChecked ||= document.getElementById("all").checked;
      return atLeastOneChecked;
    }
  
    function displayCheckboxErrorMessage(showMessage) {
      if(showMessage) {
        document.getElementById('error-msg').style.display = 'block';
      } else {
        document.getElementById('error-msg').style.display = 'none';
      } 
    }
    
    function generateForm() {
      let form = document.getElementById('subscribe-form');
      document.getElementById('vye-block').style.display = 'block'; 
      
      if(tags.length) {
        const fieldset = \`<fieldset>
                    {%- if section.settings.title != blank -%}
                      <legend align="{{ section.settings.alignment }}">{{ section.settings.title }}</legend>
                    {%- endif -%}
                    {%- if section.settings.all_products != blank -%}
                      <div style="$\{tagMargin}">
                        <input type="checkbox" id="all" name="all" onclick="allClicked(this.checked)">
                        <label style="$\{labelMargin}" for="all">{{section.settings.all_products}}</label>
                      </div>
                    {%- endif -%}
                    \` 
                    + generateTagCheckboxes() 
                    + \`<div id="error-msg" style="display: none; color:tomato">Select at least one</div>\` 
                    + \`</fieldset>\`;
        form.insertAdjacentHTML('afterBegin', fieldset);
      } 
      form.insertAdjacentHTML('beforeEnd', emailInput);
  
      form.addEventListener('submit', function(event) {
        event.preventDefault();
        if(isFormComplete()) {
          sendFormData().then(data => {
            if(data.status === 'success') {
              if('{{section.settings.subscribed}}' === '') {
                document.getElementById('description').innerHTML = 
                    \`<p>You have successfully subscribed to new product notifications!</p>\`;
              } else {
                document.getElementById('description').innerHTML = 
                    \`<p>{{section.settings.subscribed}}</p>\`;
              }
            }
          });
        } else {
          displayCheckboxErrorMessage(true);
        }
        
      });
  
      async function sendFormData() {
        const fd = new FormData(form);
  
        if('{{customer.email}}' !== '') {
          fd.append('email', '{{customer.email}}');
          fd.append('id', '{{customer.id}}');
        }
  
        if(!tags.length) {
          fd.append('all', 'on');
        }
        
        const response = await fetch(endpoint + '/subscribe' + '?shop=' + shop, 
        {
          method : "POST",  
          mode : "cors",   
          cache : "no-cache",  
          credentials : "same-origin", 
          headers : {
            "Content-Type" : "application/json",
          },
          redirect : "follow",  
          referrerPolicy :"no-referrer",                                  
          body : JSON.stringify(Object.fromEntries(fd)),  
        });
        return response.json();
      }
      
    }
  </script>
  
  <div id="vye-block" style="display: none" align="{{ section.settings.alignment }}">
    {%- if section.settings.heading != blank -%}
      {%- if section.settings.heading_size == 'large' -%}
        <h1>{{ section.settings.heading }}</h1>
      {%- elsif section.settings.heading_size == 'medium' -%}
        <h2>{{ section.settings.heading }}</h2>
      {%- elsif section.settings.heading_size == 'small' -%}
        <h3>{{ section.settings.heading }}</h3>
      {%- endif -%}
    {%- endif -%}
    <div id="description">
      {%- if section.settings.description != blank -%}
        <p>{{ section.settings.description }}</p>
      {%- endif -%}
      <form id="subscribe-form"></form>
    </div>
  </div>
  
  {% schema %}
    {
      "name": "Vye subscription form",
      "class": "vye-section",
      "settings": [
        {
          "type": "text",
          "id": "heading",
          "label": "Heading",
          "default": "New Product Notifications"
        },
        {
          "type": "select",
          "id": "heading_size",
          "label": "Heading size",
          "options": [
            {
              "value": "large",
              "label": "Large"
            },
            {
              "value": "medium",
              "label": "Medium"
            },
            {
              "value": "small",
              "label": "Small"
            }
          ],
          "default": "medium"
        },
        {
          "type": "text",
          "id": "description",
          "label": "Description",
          "default": "Subscribe to receive notifications about products added to our store."
        },
        {
          "type": "text",
          "id": "title",
          "label": "Selection Title",
          "default": "Select products you want to be notified about"
        },
        {
          "type": "text",
          "id": "all_products",
          "label": "Text for all new products",
          "default": "All new products"
        },
        {
          "type": "text",
          "id": "subscribed",
          "label": "Message after subscription",
          "default": "You have successfully subscribed to new product notifications!"
        },
        {
          "type": "select",
          "id": "alignment",
          "label": "Alignment",
          "options": [
            {
              "value": "left",
              "label": "Left"
            },
            {
              "value": "center",
              "label": "Center"
            },
            {
              "value": "right",
              "label": "Right"
            }
          ],
          "default": "left"
        },
        {
          "type": "color",
          "id": "button_color",
          "label": "Button color",
          "default": "#d4d4d4"
        }
      ],
      "presets": [
      {
        "name": "Vye: New Product Notifications"
      }
      ]
    }
  {% endschema %}`;

  const vintageCodeMarkup = showVintageCode ? (
    <Container sx={{ mt: { xs: 3, md: 5 } }}>
      <SyntaxHighlighter language="javascript" style={ocean}>
        {vintageCodeString}
      </SyntaxHighlighter>
    </Container>
  ) : null;

  const buttonVintageText = showVintageCode ? "Hide code" : "Show code";

  useEffect(() => {
    switch (true) {
      case searchParams.has("add-vintage-section"):
        scrollToHowToAddSection();
        break;
      default:
        break;
    }
  }, [searchParams]);

  const shopify_badge = (
    <Container
      component="a"
      href="https://apps.shopify.com/product-notifications-1?from=how-to-add-tag-form"
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box
        sx={{ mt: 5, mb: 1, width: { xs: "50%", md: "22%" } }}
        component="img"
        alt="Find Vye on the Shopify App Store"
        src="/shopify_badge_black.svg"
      />
    </Container>
  );

  return (
    <React.Fragment>
      <AppAppBar />
      <Container
        sx={{
          mt: { xs: 10, md: 15 },
          mb: { xs: 10, md: 15 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" gutterBottom marked="center" align="center">
          How to add a Vye product tags form
        </Typography>
        <Container
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ mt: 5, mb: 5, width: "90%" }}
            component="img"
            alt="A Vye product tags form that can be added to a Shopify shop"
            src="/onboard1.png"
          />
        </Container>
        <Container
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography align="left" sx={{ mt: 3 }}>
            Follow these steps to add a product tags form to your Shopify shop
            so that customers can subscribe to automated product email alerts.
          </Typography>
          <ol>
            <li>
              Go to <strong>Settings</strong> of Vye, go to{" "}
              <strong>Product Tags Subscription</strong>, select the tags that
              you want your customers to subscribe to. If you want your
              customers to only subscribe to all product updates don't select
              any tags. Click <strong>Save</strong>.
            </li>
            <li>
              Go to your Shopify admin, go to <strong>Online Store</strong>, and
              then <strong>Themes</strong>.
            </li>
            <li>
              Find the theme in which you want to add the form, and then click{" "}
              <strong>Customize</strong> to open the theme editor.
            </li>
            <li>
              Navigate to the page and section where you want to place the
              subscription form. Click <strong>Add section</strong>.
            </li>
            <li>
              From the drop-down menu, in the <strong>Apps</strong> section,
              select <strong>Subscription form</strong> or click the{" "}
              <strong>Search</strong> bar and enter <strong>Vye</strong>. If{" "}
              <strong>Subscription form</strong> does not show up, you may be
              using a vintage theme. See{" "}
              <Link href="/how-to-add-product-tags-form?add-vintage-section">
                how to add a product tags form on a vintage theme.
              </Link>
            </li>
            <li>
              Click <strong>Save</strong>. The form should appear on the page.
              Move it to where you want it on the page.{" "}
            </li>
            <li>
              Click on the form to customize it. To make the form cover the
              whole width of the page, click on <strong>Apps</strong> and remove
              the check from{" "}
              <strong>Make section margins the same as theme</strong>. Click{" "}
              <strong>Save</strong>.
            </li>
          </ol>
        </Container>
        <div ref={howToAddSectionRef}>
          <Typography
            variant="h4"
            gutterBottom
            marked="center"
            align="center"
            sx={{ mt: { xs: 6, md: 10 } }}
          >
            How to add a section for the Vye product tags form on a vintage
            theme
          </Typography>
        </div>
        <Container
          component="section"
          sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}
        >
          <Typography align="left" sx={{ mt: 3 }}>
            If your theme does not support Online Store 2.0, you will have to
            add a section for the Vye subscription form in your theme code
            before you can add it to your store. If you don't know if your theme
            supports Online Store 2.0, go to{" "}
            <Link href="https://themes.shopify.com/">Shopify Theme Store</Link>{" "}
            and search for your theme. If you don't find Online Store 2.0 under
            Features, then your theme does not support Online Store 2.0. Follow
            the steps below to edit your theme code to add a section for the Vye
            subscription form.
          </Typography>
          <ol>
            <li>
              Go to your Shopify admin, go to <strong>Online Store</strong>, and
              then <strong>Themes</strong>.
            </li>
            <li>
              Find the current theme. Click <strong>Actions</strong>, and then{" "}
              <strong>Edit code</strong> to open the code editor.
            </li>
            <li>
              On the left side, click on <strong>Sections</strong>, and then{" "}
              <strong>Add a new section.</strong>
            </li>
            <li>
              Enter <strong>vye-subscription-form</strong> as filename in the
              popup box and click <strong>Done</strong>. The file will be
              opened.
            </li>
            <li>
              {" "}
              Delete all the template code in the file, if there is any. Click{" "}
              <strong>Copy code to clipboard</strong> below to copy the code.
            </li>
            <li>
              Paste the copied code into the file and click{" "}
              <strong>Save.</strong> Click <strong>Exit button</strong> on the
              top left corner to close the code editor. Now you can add the form
              to your store. See <Link href="#">how to add the form.</Link>
            </li>
          </ol>
        </Container>
        <div>
          <Stack direction="row" spacing={8} sx={{ mt: { xs: 3, md: 5 } }}>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              component="a"
              onClick={handleCopyVintageToClipBoard}
            >
              Copy code to clipboard
            </Button>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              component="a"
              onClick={toggleShowVintageCode}
            >
              {buttonVintageText}
            </Button>
          </Stack>

          <Snackbar
            open={openVintage}
            autoHideDuration={3000}
            onClose={handleToasterCloseVintage}
            message="Copied"
            action={actionVintage}
          />
        </div>
        {vintageCodeMarkup}
        {shopify_badge}
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(HowToAddProductTagsForm);
