import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "../modules/components/Typography";
import AppFooter from "../modules/views/AppFooter";
import AppAppBar from "../modules/views/AppAppBar";
import withRoot from "../modules/withRoot";
import Button from "../modules/components/Button";
import Snackbar from "@mui/material/Snackbar";
import SyntaxHighlighter from "react-syntax-highlighter";
import { ocean } from "react-syntax-highlighter/dist/esm/styles/hljs";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import "../index.css";

function OnceAgainCustom() {
  const [openVintage, setOpenVintage] = useState(false);
  const [showCustomCode, setShowVintageCode] = useState(false);
  const handleCopyVintageToClipBoard = () => {
    navigator.clipboard.writeText(customCodeString);
    setOpenVintage(true);
  };

  const handleToasterCloseCustom = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenVintage(false);
  };

  const toggleShowVintageCode = () => {
    setShowVintageCode(!showCustomCode);
  };

  const actionCustom = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleToasterCloseCustom}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const customCodeString = `
  <div id="vye_accnt_container">
  <h2>Email alerts</h2>
  <p>Do you want to receive email alerts when new products are added? Select what you are interested in.</p>

  <div id="vye_accnt_loader">
    <div></div>
  </div>
  
  <div id="vye_accnt_selection">
    <div>
      <label>Brand:</label><br>
      <select
        name="brand"
        id="brand"
        multiple
        multiselect-search="true"
        multiselect-max-items="3"
        onchange="selected.vendors = (Array.from(this.selectedOptions).map(x=>x.value??x.text))"
        style="width:65vh"
      ></select>
      <br>
      <br>
      <label>Size:</label><br>
      <select
        name="size"
        id="size"
        multiple
        multiselect-search="true"
        multiselect-max-items="3"
        onchange="selected.sizes = (Array.from(this.selectedOptions).map(x=>x.value??x.text))"
        style="width: 65vh;"
      ></select>
      <br>
      <br>
      <label>Product Type:</label><br>
      <select
        name="product_type"
        id="product_type"
        multiple
        multiselect-search="true"
        multiselect-max-items="3"
        onchange="selected.product_types = (Array.from(this.selectedOptions).map(x=>x.value??x.text))"
        style="width: 65vh"
      ></select>
    </div>
    <button onclick="vye_accnt_page_save()">Save</button>
  </div>
</div>

<style>
  #vye_accnt_container {
    margin-top: 50px;
  }
  #vye_accnt_selection {
    display: none;
  }
  #vye_accnt_loader {
    border: 4px solid #f2f2f2;
    border-radius: 50%;
    border-top: 4px solid #71767a;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 0.7s linear infinite; /* Safari */
    animation: spin 0.7s linear infinite;
    display: block
  }
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>
<script>
  
  
var style = document.createElement("style");
style.setAttribute("id", "multiselect_dropdown_styles");
style.innerHTML = \`
.multiselect-dropdown{
  display: inline-block;
  padding: 2px 5px 0px 5px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: white;
  position: relative;
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
}
.multiselect-dropdown span.optext, .multiselect-dropdown span.placeholder{
  margin-right:0.5em; 
  margin-bottom:2px;
  padding:1px 0; 
  border-radius: 4px; 
  display:inline-block;
}
.multiselect-dropdown span.optext{
  background-color:lightgray;
  padding:1px 0.75em; 
}
.multiselect-dropdown span.optext .optdel {
  float: right;
  margin: 0 -6px 1px 5px;
  font-size: 0.7em;
  margin-top: 2px;
  cursor: pointer;
  color: #666;
}
.multiselect-dropdown span.optext .optdel:hover { color: #c66;}
.multiselect-dropdown span.placeholder{
  color:#ced4da;
}
.multiselect-dropdown-list-wrapper{
  box-shadow: gray 0 3px 8px;
  z-index: 100;
  padding:2px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  display: none;
  margin: -1px;
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  background: white;
}
.multiselect-dropdown-list-wrapper .multiselect-dropdown-search{
  margin-bottom:5px;
}
.multiselect-dropdown-search {
  width: 100%;
  padding: 7px;
  border: 0px;
  font-size: 1.5rem;
}

.multiselect-dropdown-list{
  padding:2px;
  height: 15rem;
  overflow-y:auto;
  overflow-x: hidden;
}
.multiselect-dropdown-list::-webkit-scrollbar {
  width: 6px;
}
.multiselect-dropdown-list::-webkit-scrollbar-thumb {
  background-color: #bec4ca;
  border-radius:3px;
}

.multiselect-dropdown-list div{
  padding: 5px;
}
.multiselect-dropdown-list input{
  height: 1.15em;
  width: 1.15em;
  margin-right: 0.35em;
}
.multiselect-dropdown-list div.checked{
}
.multiselect-dropdown-list div:hover{
  background-color: #ced4da;
}
.multiselect-dropdown span.maxselected {width:100%;}
.multiselect-dropdown-all-selector {border-bottom:solid 1px #999;}
\`;
document.head.appendChild(style);

function MultiselectDropdown(options) {
  var config = {
    search: true,
    height: "15rem",
    placeholder: "select",
    txtSelected: "selected",
    txtAll: "All",
    txtRemove: "Remove",
    txtSearch: "search",
    ...options,
  };
  function newEl(tag, attrs) {
    var e = document.createElement(tag);
    if (attrs !== undefined)
      Object.keys(attrs).forEach((k) => {
        if (k === "class") {
          Array.isArray(attrs[k])
            ? attrs[k].forEach((o) => (o !== "" ? e.classList.add(o) : 0))
            : attrs[k] !== ""
            ? e.classList.add(attrs[k])
            : 0;
        } else if (k === "style") {
          Object.keys(attrs[k]).forEach((ks) => {
            e.style[ks] = attrs[k][ks];
          });
        } else if (k === "text") {
          attrs[k] === "" ? (e.innerHTML = "&nbsp;") : (e.innerText = attrs[k]);
        } else e[k] = attrs[k];
      });
    return e;
  }

  document.querySelectorAll("select[multiple]").forEach((el, k) => {
    var div = newEl("div", {
      class: "multiselect-dropdown",
      style: {
        width: config.style?.width ?? el.clientWidth + "px",
        padding: config.style?.padding ?? "",
      },
    });
    el.style.display = "none";
    el.parentNode.insertBefore(div, el.nextSibling);
    var listWrap = newEl("div", { class: "multiselect-dropdown-list-wrapper" });
    var list = newEl("div", {
      class: "multiselect-dropdown-list",
      style: { height: config.height },
    });
    var search = newEl("input", {
      class:['multiselect-dropdown-search'],
      style: {
        display:
          el.attributes["multiselect-search"]?.value === "true"
            ? "block"
            : "none",
      },
      placeholder: config.txtSearch,
    });
    listWrap.appendChild(search);
    div.appendChild(listWrap);
    listWrap.appendChild(list);

    el.loadOptions = () => {
      list.innerHTML = "";

      if (el.attributes["multiselect-select-all"]?.value == "true") {
        var op = newEl("div", { class: "multiselect-dropdown-all-selector" });
        var ic = newEl("input", { type: "checkbox" });
        op.appendChild(ic);
        op.appendChild(newEl("label", { text: config.txtAll }));

        op.addEventListener("click", () => {
          op.classList.toggle("checked");
          op.querySelector("input").checked =
            !op.querySelector("input").checked;

          var ch = op.querySelector("input").checked;
          list
            .querySelectorAll(
              ":scope > div:not(.multiselect-dropdown-all-selector)"
            )
            .forEach((i) => {
              if (i.style.display !== "none") {
                i.querySelector("input").checked = ch;
                i.optEl.selected = ch;
              }
            });

          el.dispatchEvent(new Event("change"));
        });
        ic.addEventListener("click", (ev) => {
          ic.checked = !ic.checked;
        });
        el.addEventListener("change", (ev) => {
          let itms = Array.from(
            list.querySelectorAll(
              ":scope > div:not(.multiselect-dropdown-all-selector)"
            )
          ).filter((e) => e.style.display !== "none");
          let existsNotSelected = itms.find(
            (i) => !i.querySelector("input").checked
          );
          if (ic.checked && existsNotSelected) ic.checked = false;
          else if (ic.checked == false && existsNotSelected === undefined)
            ic.checked = true;
        });

        list.appendChild(op);
      }

      Array.from(el.options).map((o) => {
        var op = newEl("div", { class: o.selected ? "checked" : "", optEl: o });
        var ic = newEl("input", { type: "checkbox", checked: o.selected });
        op.appendChild(ic);
        op.appendChild(newEl("label", { text: o.text }));

        op.addEventListener("click", () => {
          op.classList.toggle("checked");
          op.querySelector("input").checked =
            !op.querySelector("input").checked;
          op.optEl.selected = !!!op.optEl.selected;
          el.dispatchEvent(new Event("change"));
        });
        ic.addEventListener("click", (ev) => {
          ic.checked = !ic.checked;
        });
        o.listitemEl = op;
        list.appendChild(op);
      });
      div.listEl = listWrap;

      div.refresh = () => {
        div
          .querySelectorAll("span.optext, span.placeholder")
          .forEach((t) => div.removeChild(t));
        var sels = Array.from(el.selectedOptions);
        if (
          sels.length > (el.attributes["multiselect-max-items"]?.value ?? 5)
        ) {
          div.appendChild(
            newEl("span", {
              class: ["optext", "maxselected"],
              text: sels.length + " " + config.txtSelected,
            })
          );
        } else {
          sels.map((x) => {
            var c = newEl("span", {
              class: "optext",
              text: x.text,
              srcOption: x,
            });
            if (el.attributes["multiselect-hide-x"]?.value !== "true")
              c.appendChild(
                newEl("span", {
                  class: "optdel",
                  text: "🗙",
                  title: config.txtRemove,
                  onclick: (ev) => {
                    c.srcOption.listitemEl.dispatchEvent(new Event("click"));
                    div.refresh();
                    ev.stopPropagation();
                  },
                })
              );

            div.appendChild(c);
          });
        }
        if (0 == el.selectedOptions.length)
          div.appendChild(
            newEl("span", {
              class: "placeholder",
              text: el.attributes["placeholder"]?.value ?? config.placeholder,
            })
          );
      };
      div.refresh();
    };
    el.loadOptions();

    search.addEventListener("input", () => {
      list
        .querySelectorAll(":scope div:not(.multiselect-dropdown-all-selector)")
        .forEach((d) => {
          var txt = d.querySelector("label").innerText.toUpperCase();
          d.style.display = txt.includes(search.value.toUpperCase())
            ? "block"
            : "none";
        });
    });

    div.addEventListener("click", () => {
      div.listEl.style.display = "block";
      search.focus();
      search.select();
    });

    document.addEventListener("click", function (event) {
      if (!div.contains(event.target)) {
        listWrap.style.display = "none";
        div.refresh();
      }
    });
  });
}

const vye_accnt_page_endpoint = 'https://pnot.sage-apps.com';
let vye_accnt_page_shop = '{{shop.permanent_domain}}';
let selected, selected_cpy;

fetch(
  vye_accnt_page_endpoint + '/account-page' +'?shop=' + vye_accnt_page_shop + 
  '&email=' + '{{customer.email}}')
  .then((res) => res.json())
  .then(
      (res) => {
        if(!res.selected.vendors) res.selected.vendors = [];
        if(!res.selected.product_types) res.selected.product_types = [];
        if(!res.selected.sizes) res.selected.sizes = [];
        selected = Object.assign({}, res.selected);
        selected_cpy = Object.assign({}, res.selected);
        if(res && res.options) {
          for(const v of res.options.vendors) {
            if(res.selected && res.selected.vendors && res.selected.vendors.includes(v)) 
              brand.innerHTML += \`<option selected>$\{v}</option>\`
            else
              brand.innerHTML += \`<option>$\{v}</option>\`
          }
          for(const v of res.options.product_types) {
            if(res.selected && 
               res.selected.product_types && 
               res.selected.product_types.includes(v))
              product_type.innerHTML += \`<option selected>$\{v}</option>\`
            else
              product_type.innerHTML += \`<option>$\{v}</option>\`
          }
          for(const v of res.options.sizes) {
            if(res.selected && res.selected.sizes && res.selected.sizes.includes(v))
              size.innerHTML += \`<option selected>$\{v}</option>\`
            else
              size.innerHTML += \`<option>\${v}</option>\`
          }
        }
      },
      (error) => {});

window.addEventListener("load", () => {
  document.getElementById("vye_accnt_selection").style.display = 'block';
  document.getElementById("vye_accnt_loader").style.display = 'none';
  MultiselectDropdown(window.MultiselectDropdownOptions);
});
  
window.onbeforeunload = function() {
  if(JSON.stringify(selected_cpy.vendors) !== JSON.stringify(selected.vendors) 
     || JSON.stringify(selected_cpy.product_types) !== JSON.stringify(selected.product_types) 
     || JSON.stringify(selected_cpy.sizes) !== JSON.stringify(selected.sizes))
     return('Changes you made may not be saved.');
};

function vye_accnt_page_save() {
  if(JSON.stringify(selected_cpy.vendors) !== JSON.stringify(selected.vendors) 
     || JSON.stringify(selected_cpy.product_types) !== JSON.stringify(selected.product_types) 
     || JSON.stringify(selected_cpy.sizes) !== JSON.stringify(selected.sizes)) {
    selected_cpy = Object.assign({}, selected)
    fetch(vye_accnt_page_endpoint + '/subscribe' + '?shop=' + 
          vye_accnt_page_shop + '&remove=vendors,product_types,sizes&from=account', 
      {
        method : "POST",  
        mode : "cors",   
        cache : "no-cache",  
        credentials : "same-origin", 
        headers : {
          "Content-Type" : "application/json",
        },
        redirect : "follow",  
        referrerPolicy :"no-referrer",                                  
        body : JSON.stringify({email: '{{customer.email}}', vendors: selected.vendors, 
                               product_types: selected.product_types, sizes: selected.sizes}),  
      });
    alert("Your changes have been saved.")
  }
}
</script>
  `;

  const customCodeMarkup = showCustomCode ? (
    <Container sx={{ mt: { xs: 3, md: 5 } }}>
      <SyntaxHighlighter language="html" style={ocean}>
        {customCodeString}
      </SyntaxHighlighter>
    </Container>
  ) : null;

  const buttonText = showCustomCode ? "Hide code" : "Show code";

  return (
    <React.Fragment>
      <AppAppBar />
      <Container
        sx={{
          mt: { xs: 10, md: 15 },
          mb: { xs: 10, md: 15 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" gutterBottom marked="center" align="center">
          How to add Vye custom account page code to onceagain.fr
        </Typography>

        <Container
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            mt: { xs: 3, md: 5 },
          }}
        >
          <ol>
            <li>
              Go to your Shopify admin, go to <strong>Online Store</strong>, and
              then <strong>Themes</strong>.
            </li>
            <li>
              Find the theme you are using and click on{" "}
              <strong>Edit code</strong> to open the code editor.
            </li>
            <li>
              On the left side, click on <strong>Sections</strong>, and then{" "}
              <strong>Add a new section.</strong>
            </li>
            <li>
              Enter <strong>vye-account-page-form</strong> as filename in the
              popup box, select <strong>liquid</strong> as filetype and click{" "}
              <strong>Done</strong>. The file will be opened.
            </li>
            <li>
              {" "}
              Delete all the template code in the file, if there is any. Click{" "}
              <strong>Copy code to clipboard</strong> below to copy the code.
            </li>
            <li>
              Paste the copied code into the file and click{" "}
              <strong>Save</strong>.
            </li>
            <li>
              Find and open the account page file{" "}
              <strong>customers/account.liquid</strong> and add
              <strong> &#123;% section 'vye-account-page-form' %&#125; </strong>
              to the bottom of the file just before the last{" "}
              <strong>&lt;/div&gt;</strong> and save it.
            </li>
            <li>
              Exit the code editor and check to see if the form appears on the
              account page of your store.
            </li>
          </ol>
        </Container>
        <div>
          <Stack direction="row" spacing={8} sx={{ mt: { xs: 3, md: 5 } }}>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              component="a"
              onClick={handleCopyVintageToClipBoard}
            >
              Copy code to clipboard
            </Button>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              component="a"
              onClick={toggleShowVintageCode}
            >
              {buttonText}
            </Button>
          </Stack>

          <Snackbar
            open={openVintage}
            autoHideDuration={3000}
            onClose={handleToasterCloseCustom}
            message="Copied"
            action={actionCustom}
          />
        </div>
        {customCodeMarkup}
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(OnceAgainCustom);
