import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "#F6F6F6" }}
    >
      <Container
        sx={{
          mt: { xs: 5, md: 10 },
          mb: { xs: 8, md: 15 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/app_curvy_lines.png"
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Typography
          variant="h4"
          marked="center"
          component="h2"
          sx={{ mb: { xs: 7, md: 14 } }}
        >
          What you will get from us
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box sx={item}>
              <Box
                component="img"
                src="/repair-service.svg"
                alt="suitcase"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Support
              </Typography>
              <Typography variant="h5">
                {
                  "To ensure that you are satisfied with our services, we provide full support "
                }

                {"for a year after the final version is delivered."}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={item}>
              <Box
                component="img"
                src="/software-engineer.svg"
                alt="graph"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Experience
              </Typography>
              <Typography variant="h5">
                {
                  "With our knowledge of the Shopify ecosystem and our solid experience in software development"
                }

                {", you will be in good hands. "}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
