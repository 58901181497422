import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import * as React from 'react';

import Toolbar from '../components/Toolbar';
import Typography from '../components/Typography';

function Copyright() {
    return (
        <React.Fragment>
            {'© '}
            {'SAGE Apps '}
            {new Date().getFullYear()}
        </React.Fragment>
    );
}

export default function Footer() {
    return (
        <AppBar position="static" elevation={0} component="footer" color="default" sx={{ bgcolor: "#F6F6F6"}}>
            <Toolbar style={{ justifyContent: "center" }}>
                <Typography sx={{ fontSize: {xs:10, md:13} }}><Copyright /></Typography>
                &nbsp; &#8226; &nbsp;
                <Typography sx={{ fontSize: {xs:10, md:13} }}><Link href="terms">Terms & Conditions</Link></Typography>
                &nbsp; &#8226; &nbsp;
                <Typography sx={{ fontSize: {xs:10, md:13} }}><Link href="privacy">Privacy Policy</Link></Typography>
            </Toolbar>
        </AppBar>
    );
}
